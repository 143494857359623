import React from 'react'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import error_img_404 from '@wh/common/chapter/static_hashed/img/errors/404.svg?inline'
import error_img_default from '@wh/common/chapter/static_hashed/img/errors/error.svg?inline'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'
import { ApiErrorResponse } from '@wh/common/chapter/lib/errors'
import { Box } from '@wh-components/core/Box/Box'

interface ErrorPageProps {
    /** Specifies the status code of the error */
    statusCode?: number
    error?: ApiErrorResponse
    testId?: string
    useTitleAsHeading?: boolean
}

const defineHeadingText = (useTitleAsHeading?: boolean, error?: ApiErrorResponse, statusCode?: number) => {
    if (statusCode === 404) {
        return 'Die Seite wurde nicht gefunden'
    }
    if (useTitleAsHeading && error?.title) {
        return error.title
    }
    return 'Leider ist ein Fehler aufgetreten'
}

export const ErrorPage = ({ statusCode, error, testId, useTitleAsHeading }: ErrorPageProps) => {
    const headingText = defineHeadingText(useTitleAsHeading, error, statusCode)

    return (
        <Box testId={testId} paddingTop={{ tablet: '1em' }} paddingBottom="1em">
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                backgroundColor="alert.info.background"
                padding={{ phone: 'l', tablet: 'xxxl' }}
                border={{ tablet: '1px solid' }}
                borderColor={{ tablet: 'palette.primary.main' }}
                borderRadius={{ tablet: 'm' }}
            >
                <Box marginBottom="s">
                    {statusCode === 404 ? (
                        <img
                            src={error_img_404}
                            alt="404 Error Bild"
                            css={css`
                                max-width: 400px;
                                width: 75vw;
                            `}
                        />
                    ) : (
                        <img
                            src={error_img_default}
                            alt="Error Bild"
                            css={css`
                                max-width: 150px;
                                width: 75vw;
                            `}
                        />
                    )}
                </Box>

                <Box display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column" marginBottom="s">
                    <Heading
                        text={headingText}
                        testId={`${testId}-heading`}
                        color="palette.primary.main"
                        fontSize="xxl"
                        fontWeight="bold"
                        textAlign="center"
                    />
                </Box>

                {statusCode === 404 ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                        marginBottom="m"
                    >
                        <Text>Die Seite wurde entfernt oder die URL wurde erneuert.</Text>
                        <Text>Das kann folgende Gründe haben:</Text>
                        <Text>Ein Tippfehler in der eingegebenen URL.</Text>
                    </Box>
                ) : error ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                        marginBottom="m"
                    >
                        {!useTitleAsHeading && <Text testId={`${testId}-content-title`}>{error.title}</Text>}
                        <Text testId={`${testId}-content-message`}>
                            {error.message}
                            {statusCode && <React.Fragment> Errorcode: {statusCode}</React.Fragment>}
                        </Text>
                    </Box>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                        marginBottom="m"
                    >
                        <Text>Wir entschuldigen uns für die Unannehmlichkeiten.</Text>
                        <Text>Bitte versuch es erneut. {statusCode && <React.Fragment>Errorcode: {statusCode}</React.Fragment>}</Text>
                    </Box>
                )}

                <Box>
                    <ServerRoutingAnchorLink type="button" href="/" aria-label="Zurück zur Startseite" testId="backToStartPageLink">
                        Bring mich zur Startseite!
                    </ServerRoutingAnchorLink>
                </Box>
            </Box>
        </Box>
    )
}
